import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { JaaSMeeting } from '@jitsi/react-sdk';

import '../scss/VideoCall.scss';

import FullScreenSpinner from '../components/spinner/FullScreenSpinner';

import { IVet247Appointment } from '../shared/interface/vet247.interface';

import { getVet247Service } from '../services/vet247Service';
import { getCustomerVideoCallToken, getDoctorVideoCallToken, getVet247VideoCallToken } from '../shared/service/storageService';
import { EUserType } from '../shared/enum/user.enum';

function VideoCalling() {
  let timerInterval: any, apiCallingInterval: any;
  const { roomId, serviceId } = useParams();
  const userType = new URLSearchParams(useLocation().search).get('userType');
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState<IVet247Appointment | null>(null);
  const [time, setTime] = useState(10); //seconds value

  const clearIntervalService = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }

    if (apiCallingInterval) {
      clearInterval(apiCallingInterval);
    }
  };

  const setIntervalService = () => {
    timerInterval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    apiCallingInterval = setInterval(() => {
      window.location.reload();
    }, 10000);
  };

  const getVet247 = async (serviceId: number) => {
    try {
      setLoading(() => true);
      const appointmentRes = await getVet247Service(serviceId);
      setService(() => appointmentRes?.data ?? null);
      if (appointmentRes?.data?.is_active === false) {
        setIntervalService();
      } else {
        clearIntervalService();
      }
      setLoading(() => false);
    } catch (error: any) {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    if (serviceId) {
      getVet247(Number(serviceId));
    }

    return () => clearIntervalService();
  }, [roomId]);

  return (
    <>
      {serviceId ? (
        <>
          {service?.is_active ? (
            <JaaSMeeting
              appId={'vpaas-magic-cookie-365590e44ccc4a4d8286be2dd92d89b5'}
              roomName={roomId ?? 'PleaseUseAGoodRoomName'}
              jwt={getVet247VideoCallToken() ?? ''}
              configOverwrite={{
                disableThirdPartyRequests: true,
                disableLocalVideoFlip: true,
                backgroundAlpha: 0.5,
              }}
              interfaceConfigOverwrite={{
                VIDEO_LAYOUT_FIT: 'nocrop',
                MOBILE_APP_PROMO: false,
                TILE_VIEW_MAX_COLUMNS: 4,
              }}
              spinner={FullScreenSpinner}
              onApiReady={(externalApi) => {}}
            />
          ) : (
            <div className='text-center d-flex align-items-center justify-content-center'>
              <div>
                <h2 className='text-center mt-4'>Waiting for vet</h2>
                <p>Refresh every {time} seconds</p>
              </div>
            </div>
          )}
        </>
      ) : (
        <JaaSMeeting
          appId={'vpaas-magic-cookie-365590e44ccc4a4d8286be2dd92d89b5'}
          roomName={roomId ?? 'PleaseUseAGoodRoomName'}
          jwt={userType === EUserType.CUSTOMER ? getCustomerVideoCallToken() ?? '' : userType === EUserType.DOCTOR ? getDoctorVideoCallToken() ?? '' : ''}
          configOverwrite={{
            disableThirdPartyRequests: true,
            disableLocalVideoFlip: true,
            backgroundAlpha: 0.5,
          }}
          interfaceConfigOverwrite={{
            VIDEO_LAYOUT_FIT: 'nocrop',
            MOBILE_APP_PROMO: false,
            TILE_VIEW_MAX_COLUMNS: 4,
          }}
          spinner={FullScreenSpinner}
          onApiReady={(externalApi) => {}}
        />
        // <JitsiMeeting
        //   // appId={'vpaas-magic-cookie-365590e44ccc4a4d8286be2dd92d89b5'}
        //   roomName={roomId ?? 'PleaseUseAGoodRoomName'}
        //   configOverwrite={{
        //     disableThirdPartyRequests: true,
        //     disableLocalVideoFlip: true,
        //     backgroundAlpha: 0.5,
        //   }}
        //   interfaceConfigOverwrite={{
        //     VIDEO_LAYOUT_FIT: 'nocrop',
        //     MOBILE_APP_PROMO: false,
        //     TILE_VIEW_MAX_COLUMNS: 4,
        //   }}
        //   spinner={FullScreenSpinner}
        //   onApiReady={(api: IJitsiMeetExternalApi) => {
        //     console.log('api: ', api);
        //   }}
        // />
      )}
    </>
  );
}

export default VideoCalling;
