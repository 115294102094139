function VacationComponent() {
  return (
    <div className='position-relative'>
      <img src='/images/dashboard/women-with-dog-1.jpg' alt='' className='rounded-3 w-100' />
      <div className='overlay position-absolute top-0 start-0 w-100 h-100 rounded-3 d-flex align-items-center'>
        <div className='ms-3'>
          <h4 className='text-white text-secondary font-hahmlet-medium'>Taking A Vacation?</h4>
          <h4 className='text-white text-secondary font-hahmlet-medium'>So Is Your Pet</h4>
        </div>
      </div>
    </div>
  );
}

export default VacationComponent;
